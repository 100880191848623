@import '~app/mixins';

.campaigns {
  color: $color-grey;

  button {
    + button {
      margin-left: 10px;
    }
  }

  .body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 50px;

    .header {
      background-color: white;
      height: 70px;
      color: #333;
    }

    .leftSide {
      flex-basis: 330px;
      flex-shrink: 0;

      .header {
        border-right-style: none;
        z-index: 1;
      }
    }

    .rightSide {
      flex: 1;

      .header {
        box-shadow: 2px 2px 10px #aaa;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px 0 25px;

        .left {
          .title {
            font-size: 30px;
            font-weight: bold;
            display: flex;
            align-items: flex-start;
            font-family: arial;

            svg {
              cursor: pointer;
              margin-left: 10px;
            }
          }

          .name {
            color: $color-grey;
            font-style: italic;
          }
        }
      }

      .content {
        width: 100%;
        height: calc(100vh - 70px);
        overflow: auto;

        .contentInner {
          padding: 30px 24px;
        }
      }
    }
  }
}

.noCampaigns {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;

  .start {
    width: 360px;
    margin: 0 auto;
    text-align: center;
  }

  .iconCampaigns {
    width: 105px;
    height: 100px;

    use {
      fill: #e9eaec;
    }
  }

  .title {
    color: #616366;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-top: 50px;
  }

  .subtitle {
    color: $color-grey;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-top: 25px;
  }

  .btn {
    margin-top: 50px;
    display: inline-block;
  }

  .youCan {
    width: 660px;
    margin-top: 110px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #b8b8b8;
  }

  .youCanTitle {
    line-height: 21px;
  }

  .youCanList {
    padding: 0;
    text-align: left;
    width: 100%;
    margin: 20px 15px 0;

    li {
      line-height: 24px;
      padding-left: 5px;
    }
  }

  .listWrap {
    display: flex;
  }
}

.container {
  display: none;
}
.container:first-child {
  display: block !important;
}
